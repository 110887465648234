#library {
  display: inline-block;
  vertical-align: top;
  font-size: 1.3em;

  & .thumbnail {
    height: 220px;
    text-align: center;
    position: relative;
    display: block;
    float: left;
    padding: 5px;
    text-decoration: none;
    font-weight: normal;
    color: black;

    & img {
      height: 128px;
    }

    & .title {
      display: block;
      width: 150px;
      margin: 0 auto;
    }
  }

  & .info {
    float: left;
    position: relative;
    padding-left: 20px;
    width: 500px;
  }
}

#product {
  @include clearfix();
  margin-top: 20px;
  font-size: 1.3em;

  & .toc tr td:first-child {
    width: 1px;
    white-space: nowrap;
    vertical-align: top;
    padding-right: 5px;
  }

  & ul.files li {
    list-style-type: none;

    & i {
      margin-right: 5px;
      color: black;
    }
  }

  & p.subhead {
    font-weight: bold;
    font-size: 1.2em;
  }

  & .sidebar {
    float: left;
    position: relative;
    width: 300px;
  }

  & .cover img {
    width: 180px;
  }
}
