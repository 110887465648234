.account {
  @extend .static-page;

  & .title {
    font: 3em $heading-font;
  }

  & label {
    font: 1.2em $heading-font;
  }

  & .form-group {
    padding-top: 20px;
  }

  & .required {
    margin-left: 10px;
  }

  & form div {
    margin-bottom: 20px;
  }

  & input {
    font-size: 1.1em;
    border: 1px solid $gray;
    padding: 3px 10px;

    &.large-input {
      width: 300px;
    }

    &:disabled {
      background-color: inherit;
      border: none;
    }

    &[type=submit] {
      margin-left: 20px;
      text-transform: uppercase;
      background-color: $header-bg;
      color: white;
    }
  }

  & .account-field {
    background-color: darken($main-bg, 5%);
    width: 100%;
    margin-bottom: 20px;
    font-size: 1.2em;

    & .field-label, & .field-value {
      display: table-cell;
      width: 300px;
      padding: 10px 0;
    }

    & .field-label {
      padding-left: 20px;
      font-family: $heading-font;
    }

    & .field-value a {
      margin-left: 10px;
      font-style: italic;
    }
  }
}
