#mc_embed_signup {
  background: #eee;
  max-width: 912px;
  margin: 100px auto;
  padding: 40px 50px;

  & .title {
    font-size: 36px;
    margin-bottom: 15px;
  }

  & .text {
    font-size: 18px;
    color: #666;
    margin-bottom: 15px;
    line-height: 1.8em;
  }

  & #mc-embedded-subscribe-form {
    text-align: left;

    & #mce-EMAIL {
      width: 70%;
      height: 40px;
      margin-right: 30px;

      &::placeholder {
        font-family: $allcaps-font;
        font-size: 13px;
      }
    }

    & #mc-embedded-subscribe {
      max-width: 150px;
      font-family: 'rift-soft';
      font-size: 14px;
      font-weight: bold;
      background-color: $yellow;
      color: $purple;
      padding: 0 30px;
      height: 40px;

      &:hover {
        background-color: darken($yellow, 10%);
        color: white;
      }
    }
  }
}
