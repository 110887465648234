.royal-vision {
  display: flex;

  & > section {
    flex-grow: 2;
  }

  & > img {
    width: 350px;
  }
}
