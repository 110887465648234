#sabbath-alert {
  padding: 0.75em 0;
  text-align: center;
  background-color: $yellow;
  font-size: 1.5em;
}

#philadelphian-alert {
  font-family: 'tablet-gothic-narrow';
  font-size: 1.4em;
  text-align: center;
  padding: 0.3em 0;
  background-color: #009ec7;
  color: white;
  position: relative;

  & a {
    color: white;
    font-weight: bold;
  }

  & .close {
    position: absolute;
    right: 1em;
    color: #006b89;
    cursor: pointer;
  }
}
