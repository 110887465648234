#survey {
  font-size: 16px;

  & h1 {
    font-family: $heading-font;
    font-size: 2.5em;
  }

  & .question {
    display: block;
    font-size: 1.2em;
    margin: 30px 0 15px 0;
  }

  & textarea {
    width: 75%;
    height: 5em;
  }

  & input[type="submit"] {
    @include shadow($main-bg, 0.5);
    border: none;
    background-color: $footer-bg;
    color: white;
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
}
