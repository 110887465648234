#weather {
  & img {
    vertical-align: top;
    max-width: 100%;
    margin-bottom: 20px;

    &:first-child {
      margin-right: 20px;
    }
  }
}
