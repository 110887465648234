$menu-hidden: $screen-960-max; /* Collapse navigation at screen sizes <= this */
$menu-visible: $screen-960-min; /* Show navigation at screen sizes >= this */

@mixin link-text-style {
  font-family: $heading-font;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: white;
  font-size: 1em;
  line-height: 1em;
}

.brand {
  & img {
    display: block;
    height: 120px;
    padding: 25px 0 30px 0;

    @media (max-width: $screen-480-max) {
      height: 90px;
      margin-top: 15px;
    }
  }
}

#header {
  @include clearfix;
  background-color: $header-bg;
  position: relative;

  & .brand {
    float: left;
  }

  & .nav {
    @include clearfix;
    float: right;
    min-height: 120px;
    max-width: 780px;

    /* Collapse navigation to hidden fly-out menu on small screens */
    @media (max-width: $menu-hidden) {
      transition: right 0.25s ease-out;
      background-color: $header-bg-active;
      position: absolute;
      top: 0;
      right: -250px;
      width: 250px;
      padding-right: 0;
      z-index: 1000;
      height: auto;

      &.open {
        right: 0;
      }
    }

    /* Button to open hidden navigation */
    & .menu-button {
      display: none;
      color: white;
      position: absolute;
      top: 1.1em;
      left: -1.5em;
      font-size: 3.2em;
      line-height: 1em;
      text-align: center;
      background-color: $header-bg;

      @media (max-width: $menu-hidden) {
        display: block;
      }
    }

    & .nav-row {
      position: relative;

      @media (min-width: $menu-visible) {
        @include clearfix;
      }

      /* Basic list styles */
      & ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        & li {
          & a {
            @include link-text-style;
            text-decoration: none;
            display: block;

            &.active {
              background-color: $header-bg-active;
            }
          }

          @media (min-width: $menu-visible) {
            & a:hover, &.hover > a {
              background-color: $header-bg-hover;
            }
          }
        }

        @media (max-width: $menu-hidden) {
          width: 250px;
          padding-right: 20px;

          & li {
            width: 100%;
            padding-left: 10px;

            & a {
              display: block;
              padding: 10px 0 10px 10px;
              font-size: 1.5em;
            }
          }
        }
      }

      &.user-tools {
        & ul li a {
          font-family: 'rift-soft';
          font-size: 13px;
          font-weight: normal;
        }

        @media (min-width: $menu-visible) {
          padding-bottom: 30px;

          & a {
            margin-top: 4px;
            margin-bottom: 2px;
          }
        }

        & .search-bar {
          width: 250px;
          float: right;

          @media (max-width: $menu-hidden) {
            width: 100%;
            float: none;
            font-size: 1.5em;
          }

          & input {
            @include search-bar(2.4em);
            @include link-text-style;
            text-transform: none;
            margin: 0;
            padding: 0 1em;
            height: 2.5em;
            width: 100%;

            @media (max-width: $menu-hidden) {
              background-color: $header-bg;
            }
          }
        }
      }

      /* Horizontal menus, visible on larger screens */
      @media (min-width: $menu-visible) {
        &.user-tools > ul, & ul.top-level, &.current-level > ul {
          float: right;

          & > li {
            float: left;
            position: relative;

            &:last-child {
              padding-right: 0;
            }

            & a {
              padding: 0.5em 1em;
              display: block;
            }
          }
        }
      }

      &.main-links {
        & ul.top-level {
          @media (max-width: $menu-hidden) {
            & ul {
              display: none;
            }
          }

          @media (min-width: $menu-visible) {
            & > li {
              & a {
                font-size: 1.3em;
                font-weight: 300;
              }

              & > ul {
                display: none; /* Hide the "real" submenus at large sizes - using .sub-level ones powered by JS */
              }
            }
          }
        }

        /* Duplicate submenus of top-level pages, arranged and revealed / hidden by JS.
         * Need to do it this way so the ul's don't get squished by their parents and to right-align menus near the screen edge. */
        & ul.sub-level {
          transition: opacity 0.25s ease-out;
          background-color: $header-bg-hover;
          white-space: nowrap;
          position: absolute;
          visibility: hidden;
          opacity: 0;
          z-index: 1000;

          & > li > a {
            padding-left: 10px;
            padding-right: 10px;
          }

          & ul, & ul > li > a {
            padding-left: 10px;
            padding-right: 10px;
          }

          & a {
            padding-top: 5px;
            padding-bottom: 5px;
            display: block;

            &.active {
              background-color: $header-bg-hover;
            }
          }

          @media (max-width: $menu-hidden) {
            display: none; /* Don't need these for the fly-out navigation */
          }
        }
      }

      @media (max-width: $menu-hidden) {
        &.current-level {
          display: none; /* Hide sibling links on the fly-out navigation */
        }
      }
    }
  }
}
