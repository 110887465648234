.search-results {
  & .search-bar input {
    @include search-bar(1.1em);
    width: 50%;
    font-size: 1.1em;
    padding: 10px 10px;
  }

  & .search-info {
    padding: 10px 0;
    color: $gray;
    font-size: 1.1em;
    text-transform: uppercase;
  }
}
