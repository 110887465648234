$article_text_color: black;

@mixin article-info {
  padding-bottom: 30px;

  & .title {
    margin-bottom: 5px;
    font-size: 1.7em;

    & a {
      color: $article_text_color;
      text-decoration: none;

      &:hover {
        color: lighten($article_text_color, 30%);
      }
    }
  }

  & .divider {
    width: 50px;
    border-bottom: 2px solid $gray;
    margin: 10px 0 5px 0;
  }

  & .quip {
    font-family: $main-font;
    color: $red;
    font-size: 1.5em;
  }

  & .byline {
    color: $article_text_color;
    font-size: 1.2em;
  }

  & .info-footer {
    @include clearfix;
    margin-top: 10px;
    width: 100%;
    color: $article_text_color;

    & .date {
      float: left;
    }
  }
}

.landing-page, .search-results {
  @include articles-wrapper;
  margin-top: 10px;

  & .featured {
    @include clearfix;
    position: relative;

    & .featured-image {
      float: left;

      & img {
        width: 100%;
        vertical-align: top;
      }
    }

    & .featured-info {
      float: left;

      & .info {
        @include article-info;
        width: inherit;
        vertical-align: top;
        margin-top: 12px;

        & .title {
          font: 2em $heading-font;
        }

        & .quip {
          font-size: 1.5em
        }

        & .byline {
          font-size: 1.3em;
        }

        & .info-footer {
          width: inherit;
          font-size: 1em;
        }
      }
    }

    @media (min-width: $screen-1280-min) {
      & .featured-image {
        width: 890px;
      }

      & .featured-info {
        width: 200px;
        margin-left: 30px;
      }
    }

    @media (min-width: $screen-1024-min) and (max-width: $screen-1280-max) {
      & .featured-image {
        width: 570px;
      }

      & .featured-info {
        width: 270px;
        margin-left: 30px;
      }
    }

    @media (min-width: $screen-768-min) and (max-width: $screen-1024-max) {
      & .featured-image {
        width: 430px;
      }

      & .featured-info {
        width: 200px;
        margin-left: 30px;
      }
    }

    @media (max-width: $screen-768-max) {
      & .featured-image, & .featured-info {
        width: 100%;
      }
    }
  }

  & .articles {
    @include clearfix;
    margin: 0 -15px;

    @media (min-width: $screen-400-min) and (max-width: $screen-480-max) {
      margin: 0 -2.5%;
    }

    @media (max-width: $screen-480-max) {
      margin: 0;
    }

    & .article-box {
      position: relative;
      margin-top: 30px;
      height: 400px;

      & .info {
        @include article-info;
      }
    }
  }

  & .pagination {
    margin-top: 20px;

    & .page-link {
      display: inline-block;
      margin-right: 20px;
      text-align: center;

      & a {
        text-decoration: none;
        text-transform: uppercase;
        color: $article_text_color;

        &:hover {
          color: lighten($article_text_color, 30%);
        }

        & i {
          font-size: 4em;
          height: 20px;
        }
      }
    }
  }
}

.image-container {
  position: relative;
  width: 100%;

  &:before {
    content: "";
    display: block;
    padding-top: 56.25%; // Force 16x9 aspect ratio
  }

  & .image-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  & .video-overlay {
    position: absolute;
    width: 100%;
    color: white;
    opacity: 0.6;
    font-size: 5em;
    text-align: center;
    top: 50%;
    margin-top: -0.5em;
    z-index: 100;
  }
}
