.owl-theme {
  & .owl-nav {
    & button[class*='owl-'] {
      font-family: sans-serif;
      position: absolute;
      margin-top: -0.75em;
      top: 50%;
      font-size: 50px;
      color: white;
      text-shadow: black 0 0 3px;

      opacity: 0;
      transition: opacity 0.2s ease;

      &:hover {
        background: transparent;
      }
    }

    & .owl-prev {
      left: 0;
    }

    & .owl-next {
      right: 0;
    }
  }

  &:hover .owl-nav button[class*='owl-'] {
    opacity: 1;
  }

  & .owl-dots {
    position: absolute;
    margin: 0 auto;
    bottom: 20px;
    left: 0;
    right: 0;

    & .owl-dot {
      & span {
        background: white;
        box-shadow: 0 0 3px 0 black;
        opacity: 0.5;
        transition: opacity 200ms ease;
      }

      &.active span, &:hover span {
        background: white;
        opacity: 1;
      }
    }
  }
}

.main-carousel img {
  width: 1120px !important;
  height: 350px;
}
