.article-wrapper {
  max-width: 860px;
  margin: 10px auto;

  & .banner {
    margin: 50px auto 0 auto;
    position: relative;
    overflow: hidden;
    font-size: 1.3em;

    @media (min-width: $screen-660-min) and (max-width: $screen-1024-max) {
      height: 360px;
    }

    @media (min-width: $screen-480-min) and (max-width: $screen-660-max) {
      height: 270px;
    }

    @media (min-width: $screen-400-min) and (max-width: $screen-480-max) {
      height: 240px;
    }

    @media (max-width: $screen-400-max) {
      height: 160px;
    }

    & img {
      max-width: 100%;
    }

    &.video {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;

      & iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  & .image, & .banner {
    & img {
      display: block;
    }
  }

  & .image.left, & .image.right {
    max-width: 65%;
  }

  & .image-with-credit, & .banner.image {
    & .credit {
      color: $gray;
      font-family: $allcaps-font;
      font-size: 8px;
      width: 100%;
      text-align: right;
      display: block;
    }
  }

  & .image-with-caption, & .banner.image {
    & .caption {
      border-top: 1px dotted $gray;
      width: 100%;
      text-align: center;
      font-size: 0.8em;
      color: #555;
    }
  }

  & .article {
    @include clearfix;
    padding: 0 20px;

    & .title {
      font: 64px $main-font;
      padding: 0 80px;
      font-weight: 300;
      color: black;
      margin: 15px auto;
      text-align: center;

      @media (max-width: 1169px) {
        padding: 0;
      }
    }

    & .info-block, & .article-content {
      margin-left: auto;
      margin-right: auto;

      @media (max-width: $screen-1024-max) {
        width: 100%;
      }
    }

    & .article-inner {
      position: relative;
    }

    & .article-sidebar {
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;

      @media (max-width: $screen-1280-max) {
        float: none !important;
        width: 100%;
        margin-top: 0;
      }

      & h3 {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 300;
        margin-top: 40px;
      }

      & ul {
        list-style-type: none;
        padding-left: 0;

        & li a {
          @include clearfix;
          font-family: $heading-font;
          color: black;

          &:hover {
            color: lighten(black, 30%);
          }

          & div {
            display: table-cell;
            vertical-align: top;
          }

          & div.image, & span.image {
            padding-right: 20px;

            & img {
              width: 100px;
            }
          }

          & div.link {
            width: 1000px;
            overflow: hidden;
          }
        }
      }
    }

    & .info-block {
      margin-top: 40px;
      text-align: center;

      & .quip {
        font: normal 400 20px/23px $heading-font;
        color: #565656;
        margin-bottom: 10px;
        padding: 0 80px;

        @media (max-width: 1169px) {
          padding: 0;
        }
      }

      & .byline {
        font-family: $allcaps-font;
        font-weight: 700;
        font-size: 21px;
        line-height: 26px;
        color: #bfbfbf;

        & a {
          color: inherit;

          &:hover {
            color: $purple;
            text-decoration: none;
          }
        }
      }
    }

    & .article-content {
      display: block;
      margin-top: 60px;
      font: 20px $main-font;
      padding: 0 80px;
      height: auto;
      color: black;

      @media (max-width: 1169px) {
        padding: 0;
      }

      & a {
        text-decoration: underline;
      }

      & .sidebar_anchor {
        position: relative;

        & .sidebar {
          position: absolute;
          left: 580px;
          width: 380px;
        }
      }

      & .smallcaps {
        font-variant: small-caps;
      }

      & .subhead {
        font-size: 1.3em;
      }

      & h2 {
        font-size: 1.3em;
        font-weight: normal;
      }

      & .pull-left {
        margin-right: 10px;
        margin-bottom: 10px;
      }

      & img {
        max-width: 100%;
      }

      & blockquote {
        color: #565656;
        border-left: 5px solid #EEEEEE;
        padding-left: 1em;
        margin: 0;
      }
    }
  }
}
