.help {
  @include clearfix;

  & .pull-left {
    width: 50%;
  }

  & .pull-right {
    width: 30%;
  }

  & .center {
    text-align: center;
  }

  & .contact-errors {
    color: $red;
  }

  & form {
    & input, & textarea {
      display: block;
      width: 100%;
      font-size: 1em;
      border: 1px solid lighten($header-bg, 20%);
      padding: 3px 10px;
      color: black;
      margin-top: 10px;
    }

    & textarea {
      height: 10em;
    }

    & input[type="checkbox"] {
      display: inline;
      width: auto;
    }

    & input[type="submit"] {
      width: auto;
      padding: 5px 10px;
      border: none;
      background-color: $header-bg;
      color: white;
    }
  }

  & .minister-only {
    display: none;
  }

  &.minister {
    width: 50%;

    & .minister-only {
      display: block;
    }
  }
}
