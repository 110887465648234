.article-carousel-wrapper {
  @include articles-wrapper;

  & h2 {
    font-size: 2em;
    letter-spacing: 3px;
    font-weight: 300;
    margin-top: 30px;
  }

  & .article-carousel {
    @include clearfix;

    @media (min-width: $screen-400-min) and (max-width: $screen-480-max) {
      margin: 0 -2.5%;
    }

    @media (max-width: $screen-480-max) {
      margin: 0;
    }

    & .article-box {
      margin: 0;
    }
  }

  & .owl-nav {
    & .owl-prev, & .owl-next {
      top: 60px !important;
    }

    & .owl-prev {
      left: -15px !important;
    }

    & .owl-next {
      right: -15px !important;
    }
  }
}
