.social-media-buttons {
  font-size: 32px;
  margin-top: 30px;
  margin-bottom: 20px;

  & a {
    color: #bfbfbf;

    &:hover {
      text-decoration: none;
    }
  }

  & .link {
    display: inline-block;
    position: relative;

    & .popup {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.2s linear, opacity 0.2s linear;
      font-size: 0.5em;
      white-space: nowrap;
      position: absolute;
      top: -2em;
      left: 0;
      color: #fff;
      background-color: lighten(#000, 20%);
      padding: 5px;
      border-radius: 5px;
      cursor: default;
    }

    & a {
      position: relative;
      padding: 5px;

      &:hover + .popup {
        visibility: visible;
        opacity: 1;
      }
    }

    &:hover {
      & .fa-print {
        color: #000;
      }

      & .fa-twitter {
        color: #55acee;
      }

      & .fa-facebook {
        color: #3b5998;
      }

      & .fa-youtube {
        color: #cd201f;
      }

      & .fa-pinterest {
        color: #bd081c;
      }

      & .fa-instagram {
        color: #A1755C;
      }

      & .fa-linkedin {
        color: #007bb6;
      }
    }
  }

  & .twitter-follow {
    float: right;

    @media (max-width: $breakpoint-768) {
      display: block;
      float: none;
      margin-top: 20px;
    }

    & i {
      margin-left: 10px;
    }

    &:hover {
      color: black;

      & .fa-twitter {
        color: #55acee;
      }
    }
  }
}

/* Social buttons on the right of the article */
.article-sidebar .social-media-buttons {
  margin-top: 0;

  & .link {
    display: block;
    width: 100%;

    & a {
      display: block;
    }
  }

  & .popup {
    top: 10px !important;
    left: 3em !important;
  }

  @media (max-width: $screen-1280-max) {
    display: none;
  }
}
