@mixin shadow($bgcolor, $opacity) {
  $shadow_background: rgba($bgcolor, $opacity);
  $ms-background: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#{ie-hex-str($shadow_background)}, endColorstr=#{ie-hex-str($shadow_background)})";
  
  background-color: $bgcolor;
  background: $bgcolor transparent;
  background: $shadow_background;
  filter: #{$ms-background};
  -ms-filter: $ms-background;
}

@mixin clearfix {
  *zoom: 1;

  &:before, &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin center($width) {
  position: relative;
  margin: 0 auto;
  max-width: $width;
}

@mixin shadow-box {
  @include shadow($header-bg, 0.8);
  color: $gray;
  width: 50%;
  padding: 15px 60px;
  margin-top: 55px;
  width: 560px;

  $large_form_width: 300px;

  @media (max-width: $screen-768-max) {
    padding: 15px;
    width: 100%;
    margin-top: 0;
  }

  .title {
    font-size: 4em;
    font-family: $heading-font;
  }

  .form {
    @media (min-width: $screen-768-min) {
      width: $large_form_width;
    }

    .large-input {
      input, button {
        width: 100%;
        font-size: 1.1em;
        border: none;
        padding: 3px 10px;
        color: black;
      }

      label {
        font-size: 1.8em;
        font-family: $heading-font;
      }

      span.hint {
        margin-left: 15px;
      }

      &.submit-container {
        @media (min-width: $screen-768-min) {
          width: 450px;

          input {
            width: $large_form_width;
          }
        }

        input[type=submit], button {
          @include shadow($main-bg, 0.5);
          margin-left: 40px;
          width: 100px;
          border: none;
          color: $main-bg;
          text-transform: uppercase;

          @media (max-width: $screen-768-max) {
            margin-left: 0;
            margin-top: 30px;
            display: block;
          }
        }
      }
    }
  }
}

@mixin search-bar($height) {
  $search-color: white;
  background-color: $header-bg-active;
  color: $search-color;
  border: none;

  &::placeholder {
    color: $search-color;
    line-height: normal;
    text-transform: uppercase;
  }

  &:-moz-placeholder, &::-moz-placeholder {
    line-height: $height;
  }
}

@mixin articles-wrapper {
  position: relative;
  margin: 0 auto;

  @media (min-width: $screen-1280-min) {
    width: $content-width-1120;
  }

  @media (min-width: $screen-1024-min) and (max-width: $screen-1280-max) {
    width: $content-width-870;
  }

  @media (min-width: $screen-960-min) and (max-width: $screen-1024-max) {
    width: $content-width-660;
  }

  @media (min-width: $screen-768-min) and (max-width: $screen-960-max) {
    width: $content-width-660;
  }

  @media (min-width: $screen-660-min) and (max-width: $screen-768-max) {
    width: $content-width-570;
  }

  @media (min-width: $screen-480-min) and (max-width: $screen-660-max) {
    width: $content-width-430;
  }

  @media (min-width: $screen-400-min) and (max-width: $screen-480-max) {
    width: $content-width-87;
  }

  @media (max-width: $screen-400-max) {
    width: $content-width-90;
  }

  .article-box {
    float: left;
    margin: 0 15px;
    position: relative;

    @media (min-width: $screen-1280-min) {
      width: 200px;
    }

    @media (min-width: $screen-1024-min) and (max-width: $screen-1280-max) {
      width: 270px;
    }

    @media (min-width: $screen-768-min) and (max-width: $screen-1024-max) {
      width: 200px;
    }

    @media (min-width: $screen-660-min) and (max-width: $screen-768-max) {
      width: 270px;
    }

    @media (min-width: $screen-480-min) and (max-width: $screen-660-max) {
      width: 200px;
    }

    @media (min-width: $screen-400-min) and (max-width: $screen-480-max) {
      width: 45%;
      margin: 0 2.5%;
    }

    @media (max-width: $screen-400-max) {
      width: 100%;
      margin: 0;
    }

    img {
      width: 100%;
    }

    .title {
      font-family: $heading-font;
      font-size: 1.3em;
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
