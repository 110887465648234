.centered {
  @include center($content-width-1120);
}

.clearfix {
  @include clearfix;
}

div {
  & > .edit {
    font-size: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $header-bg-active;
    padding: 3px;
    margin: 5px 0 0 5px;
    color: white !important;
    display: none;
    visibility: hidden;
  }

  &:hover > .edit {
    display: block;
    visibility: visible;
  }
}

body {
  font-family: $main-font;
  font-size: 12px;
  margin: 0;

  & a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }

  & .canvas {
    width: 100%;
    position: relative;
    background-color: $main-bg;
    overflow-x: hidden;

    & .content {
      max-width: $content-width-1120;
      margin: 0 auto;
      position: relative;

      & .banner {
        width: 100%;
        display: block;
      }

      & .floating-container {
        width: 100%;
        height: 100%;

        @media (min-width: $screen-768-min) {
          position: absolute;
          top: 0;
          left: 0;
          overflow: hidden;
        }
      }
    }

    & .centered-wrapper {
      position: relative;
      margin: 0 auto;
      width: $content-width-1120;

      @media (min-width: $screen-1024-min) and (max-width: $screen-1280-max) {
        width: $content-width-870;
      }

      @media (min-width: $screen-768-min) and (max-width: $screen-1024-max) {
        width: $content-width-660;
      }

      @media (min-width: $screen-660-min) and (max-width: $screen-768-max) {
        width: $content-width-570;
      }

      @media (min-width: $screen-480-min) and (max-width: $screen-660-max) {
        width: $content-width-430;
      }

      @media (min-width: $screen-400-min) and (max-width: $screen-480-max) {
        width: $content-width-87;
      }

      @media (max-width: $screen-400-max) {
        width: $content-width-90;
      }
    }
  }

  & .static-page {
    font-size: 1.4em;
    margin-top: 10px;

    & a {
      text-decoration: underline;
    }
  }
}
