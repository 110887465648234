#browse {
  & h1 {
    font-size: 64px;
    font-weight: normal;
    text-transform: uppercase;
    color: $purple;
    padding-top: 50px;
    padding-bottom: 20px;
    border-bottom: 2px solid #ddd;
  }

  & .article-box {
    display: inline-block;
    vertical-align: top;
    margin-right: 40px;
    max-width: 258px;

    &:last-child {
      margin-right: 0;
    }

    & img {
      width: 100%;
    }

    & .title a {
      font-size: 20px;
      color: black;
    }

    & .quip {
      margin-top: 10px;
      font-size: 14px;
      color: #666;
    }

    & .byline {
      font-family: $allcaps-font;
      font-size: 14px;
      color: #666;
      margin-top: 5px;

      & a {
        color: inherit;
      }
    }
  }

  & .subnav {
    display: inline-block;
    font-size: 20px;

    & ul {
      padding-left: 0;
      width: 258px;
      margin: 0;

      & li {
        list-style-type: none;
        margin-bottom: 15px;
      }
    }
  }

  & #resources {
    max-width: 958px;
    margin: 90px auto 0 auto;
    padding: 46px;
    background-color: #eee;

    & .title {
      font-family: $heading-font;
      text-transform: uppercase;
      font-size: 13px;
      padding-top: 10px;
      color: #666;
      border-top: 1px solid #666;
    }

    & .column {
      float: left;
      width: 33.3%;

      & a {
        margin-top: 20px;
        display: block;
        font-size: 20px;
      }
    }
  }
}
