.resources {
  @extend .static-page;

  & .section {
    @include clearfix;
    margin-bottom: 50px;

    & .float {
      display: inline-block;
      vertical-align: top;
      width: 70%;
      margin-left: 30px;
    }

    & img {
      vertical-align: top;
      width: 132px;
    }
  }
}
