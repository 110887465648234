.author-page {
  & .author-name {
    font-size: 3em;
    font-family: $heading-font;
    font-weight: bold;
  }

  & .author-info {
    @include clearfix;
    border-top: 1px dotted $red;
    padding-top: 15px;
    margin-bottom: 30px;

    & img, & .author-description {
      float: left;
    }

    & img {
      margin-right: 30px;
    }

    & .author-description {
      width: 660px;

      & .author-social-media {
        float: right;
        margin-top: 20px;

        & i {
          color: $gray;
          font-size: 2.5em;
          margin-left: 5px;
        }
      }
    }

    @media (min-width: $screen-1024-min) and (max-width: $screen-1280-max) {
      & img { width: 270px; }
      & .author-description { width: 570px; }
    }

    @media (max-width: $screen-1024-max) {
      & .author-description {
        margin-top: 10px;
        width: 100%;

        & .author-social-media {
          float: none;

          & i {
            margin-left: 0;
            margin-right: 5px;
          }
        }
      }
    }

    @media (max-width: $screen-480-max) {
      & img {
        max-width: 100%;
      }
    }
  }
}
