.events {
  @include articles-wrapper;
  margin-top: 10px;

  & .featured {
    & .title {
      font-size: 1.5em;
    }
  }

  & .articles {
    & .title {
      font-size: 1.2em;
    }
  }
}
