@mixin yellow-button {
  color: $purple;
  text-decoration: none;
  font-family: 'rift-soft';
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  background-color: $yellow;
  padding: 10px 30px;
  border-radius: 3px;
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    text-decoration: none;
    background-color: darken($yellow, 10%);
    color: white;
  }
}

@mixin white-title {
  font-family: $main-font;
  font-size: 28px;
  color: white;
  /* text-shadow: 0px 0px 2px black; */
}

#home {
  @include clearfix;

  & .main-carousel {
    background-color: black;

    & .owl-stage-outer {
      /* Temporary & inadequate fix for owl carousel bug
       * https://github.com/OwlFonk/OwlCarousel2/issues/303 */
      max-height: 350px;
    }

    & .owl-item {
      opacity: 0.4;

      &.center {
        opacity: 1;
      }
    }
  }

  & .intro-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 120px auto;

    & .subsection {
      /* margin-left: 46px; */
      width: 258px;
      text-align: center;

      &:first-child {
        margin-left: 0;
      }

      & picture, & img {
        width: 100%;
        display: block;
        margin-bottom: 20px;
      }

      & .text {
        margin: 0 50px;
        font-size: 24px;
        color: #333;
      }

      & a:hover {
        text-decoration: underline;
      }
    }
  }

  & .legacy {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 70px;

    & .subsection {
      display: flex;
      max-width: 550px;

      @media (max-width: 550px) {
        display: block;
      }

      & picture, & img {
        display: block;
      }

      & .text {
        flex: 1;
        margin-left: 23px;

        & .title {
          display: block;
          font-size: 36px;
          line-height: 36px;
          margin-top: 15px;
          margin-bottom: 15px;
          color: black;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        & span {
          font-size: 14px;
          line-height: 1.5em;
        }
      }

      &:first-child .text {
        margin-right: 100px;
      }
    }
  }

  & .literature {
    margin-bottom: 70px;
    position: relative;
    display: flex;

    & .text {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 15px 0 35px 35px;
      width: 100%;
      background-image: linear-gradient(to top, black, transparent);


      & .title {
        @include white-title;
        text-shadow: 0px 0px 2px black;
        margin-bottom: 15px;
      }


      & .blurb {
        max-width: 650px;
        font-size: 16px;
        color: white;
      }

      & .library-link {
        @include yellow-button;
        padding-top: 3px;
        padding-bottom: 3px;
        line-height: 26px;
        margin-left: 30px;
      }

      & .blurb, & .library-link {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  & .featured-work {
    display: flex;

    & a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    & picture {
      display: contents;

      & img {
        display: block;
      }
    }

    & .subsection {
      display: flex;
      flex-wrap: wrap;

      & > div {
        display: flex;
        position: relative;
      }

      &:first-child {
        width: 734px;
        margin-right: 6px;
      }
    }

    & .left-large {
      margin-bottom: 6px;
    }

    & .left-small:last-child {
      margin-left: 6px;
    }

    & .right-small {
      margin-bottom: 6px;
    }

    & .title {
      @include white-title;
      background-image: linear-gradient(to bottom, black, transparent);
      width: 100%;
      padding: 26px 20px;
      position: absolute;
      top: 0;
      left: 0;
    }

    & .left-small, & .right-large {
      & .title {
        background-image: linear-gradient(to top, black, transparent);
        top: unset;
        bottom: 0;
      }
    }

    & .callout {
      position: absolute;
      bottom: 30px;
      left: 30px;

      & div {
        font-size: 76px;
        color: white;
        text-shadow: 0px 0px 2px black;
        margin-bottom: 20px;
      }

      & a {
        @include yellow-button;
      }
    }
  }

  & .quick-links {
    border-top: 1px solid #999;
    margin-top: 100px;

    & .title {
      font-family: 'tablet-gothic-narrow';
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      color: #999;
      margin-top: 10px;
    }

    & .links {
      display: flex;
      font-size: 16px;

      & .subsection {
        flex-grow: 1;

        & a {
          display: block;
          margin-top: 20px;
        }
      }
    }
  }

  & .contact-minister {
    margin-top: 100px;
    text-align: center;

    & .title {
      font-size: 36px;
    }

    & .blurb {
      font-size: 20px;
      color: #666;
    }

    & a {
      @include yellow-button;
      display: inline-block;
      margin-top: 15px;
    }
  }
}

.caption-wrapper {
  margin: 0 auto;
  position: relative;
  max-width: 1120px;

  & .caption {
    display: none;
    padding: 10px;
    overflow: hidden;

    @media (min-width: $screen-768-min) {
      @include shadow(black, 0.8);
      position: absolute;
      width: 256px;
      height: 145px;
      bottom: 30px;
      left: 30px;
      z-index: 200;
    }

    @media (max-width: $screen-768-max) {
      width: 100%;
      background-color: $header-bg;
    }

    &.guest {
      height: auto;
      max-height: 145px;
    }

    & a {
      text-decoration: none;
      display: inline-block;

      & .title {
        font-size: 1.8em;
        line-height: 1.2em;
        color: white;
      }

      & .quip {
        margin-top: 5px;
        color: white;
      }
    }
  }
}
