#magazines {
  @include clearfix;

  & .magazine-wrapper {
    margin: 0 -15px;

    & .magazine {
      float: left;
      margin: 17px 15px;
      text-align: center;
      width: 200px;
      height: 288px;

      & a {
        color: inherit;
      }

      & .issue {
        font-size: 1.3em;
      }

      & img {
        width: 100%;
      }

      &:first-child {
        width: 430px !important;
        height: 590px !important;
      }
    }
  }
}
