#not-found {
  & .search-box {
    @include shadow-box;
    right: 0;
    padding-top: 40px;
    padding-bottom: 50px;

    & .title {
      font-size: 2.5em;
    }

    & .form {
      margin-top: 30px;

      & input {
        @include search-bar(1.2em);
      }
    }

    & .return a {
      color: $purple;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
