#footer {
  width: 100%;
  background-color: $footer-bg;
  position: relative;
  margin-top: 100px;
  font-family: 'rift-soft';
  font-weight: bold;
  letter-spacing: 0.02em;

  & a {
    color: white;
  }

  & .wrapper {
    @include center($content-width-1120);

    &.top {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  & .social {
    text-align: right;
    padding-bottom: 30px;

    & a {
      font-size: 32px;
      line-height: 1em;
      font-weight: normal;
      margin-left: 10px;
    }
  }

  & .links {
    padding: 20px 0;

    & a {
      margin-right: 25px;
      font-size: 13px;
    }
  }

  & .copyright {
    width: 100%;
    color: #999;
    padding-bottom: 20px;
    font-size: 13px;
  }
}
