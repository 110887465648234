$purple: #4d2a49;
$off-white: #fffaf0;
$green: #4e4841;
$red: #8a3941;
$orange: #c65429;
$gray: #ababab;
$yellow: #e7bf36;

$main-bg: #fff;
$header-bg: $purple;
$header-bg-active: black;
$header-bg-hover: darken($header-bg, 15%);
$footer-bg: #333;

$main-font: "abril-text";
$heading-font: "tablet-gothic-narrow";
$allcaps-font: "rift-soft";

// Don't hate. If you can come up with a better naming convention for eight breakpoints, I'd like to see it.
$breakpoint-400: 400px;
$breakpoint-480: 480px;
$breakpoint-660: 660px;
$breakpoint-768: 768px;
$breakpoint-960: 960px;
$breakpoint-1024: 1024px;
$breakpoint-1280: 1280px;
$breakpoint-1600: 1600px;

$content-width-90: 90%;
$content-width-87: 87.5%;
$content-width-430: 430px;
$content-width-570: 570px;
$content-width-660: 660px;
$content-width-870: 870px;
$content-width-1120: 1170px;
$content-width-1600: 1600px;

$screen-400-max: $breakpoint-400;
$screen-400-min: $breakpoint-400 + 1;
$screen-480-max: $breakpoint-480;
$screen-480-min: $breakpoint-480 + 1;
$screen-660-max: $breakpoint-660;
$screen-660-min: $breakpoint-660 + 1;
$screen-768-max: $breakpoint-768;
$screen-768-min: $breakpoint-768 + 1;
$screen-960-max: $breakpoint-960;
$screen-960-min: $breakpoint-960 + 1;
$screen-1024-max: $breakpoint-1024;
$screen-1024-min: $breakpoint-1024 + 1;
$screen-1280-max: $breakpoint-1280;
$screen-1280-min: $breakpoint-1280 + 1;
$screen-1600-max: $breakpoint-1600;
$screen-1600-min: $breakpoint-1600 + 1
