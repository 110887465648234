@media screen {
  /* Apply border-box to all elements */
  *, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  #flash_container {
    text-align: left;
  }

  .owl-dot span {
    box-shadow: 0 0 3px 0 black;
  }

  .owl-prev, .owl-next {
    text-shadow: black 0 0 3px;
  }

  /* Helper classes */
  .left, [data-float="left"] {
    float: left;
    margin: 0 10px 10px 0;
  }

  .right, [data-float="right"] {
    float: right;
    margin: 0 10px 10px 0;
  }

  .smallcaps {
    font-variant: small-caps;
  }

  .subhead {
    font-size: 1.3em;
  }
}
