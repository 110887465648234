#login-page {
  position: relative;

  & .login-panel {
    @include shadow-box;

    & .reset a {
      font-style: italic;
      color: white;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 768px) {
  .hide-phone {
    display: none !important;
  }
}
