.about {
  @extend .static-page;

  & .text-wrap {
    float: left;
    margin-right: 20px;
  }

  & h1 {
    font: 2em $heading-font;
  }

  & .social {
    margin: 60px 0;

    & .social-media-buttons {
      font-size: 2em;

      & .link {
        margin: 0 5px;
      }

      & .popup {
        font-size: 0.5em !important;
      }
    }
  }

  & .banner.video {
    margin-bottom: 30px;
  }
}
