@media print {
  body {
    font: 12pt $main-font;
    background-color: white;
    color: #000;

    & #header, & .banner, & .social-media-buttons, & .article-sidebar, & img, & #sabbath-alert {
      display: none;
    }

    & #footer {
      margin-top: 2.5em;

      & .wrapper {
        display: none;
      }
    }

    & a {
      text-decoration: none;
      color: inherit;
    }

    & .article, & .about {
      & .title {
        font: bold 2em $heading-font;
      }

      & .info-block {
        margin-top: 0.5em;
        margin-bottom: 2.5em;

        & .byline {
          font: 1.1em $heading-font;
          margin-top: 0.5em;
        }
      }

      & .subhead {
        font-size: 1.2em;
      }

      & .smallcaps {
        font-variant: small-caps;
      }
    }
  }
}
